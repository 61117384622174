
.section{
  font-family: CormorantGaramond-Bold; 
  font-size: 50px;
}

.p{
  font-family: CormorantGaramond; 
  font-size: 25px;
}

#profile {
 float:right;
 height: 590px;
 width: 90% 
 } 

 .parent {
  padding: 20px;
  padding-left: 0px;
  width:80%;
  float:right;
}

.child {
  width: 50%;
  float: left;
  padding: 5px;
  padding-left:20px;
}

@media screen and (max-width : 900px) {
  .section{
    font-size: 30px;
  }
  .p{
    font-size: 20px;
  }
  .child {
    width: 100%;
    padding: 10px;
    padding-left:20px;
  }
  #profile {
    height: 80%;
    width: 80% 
    } 
}

@media screen and (max-width : 600px) {
  .p{
    font-size: 15px;
  }
  .child {
    width: 100%;
    padding: 10px;
    padding-left:20px;
  }
  #profile {
    float:none;
    height: 100%;
    width: 100% 
    } 
}






