.section-portfolio{
    font-family: CormorantGaramond-Bold; 
    font-size: 50px;
  }

.project{
    font-family: CormorantGaramond-Bold; 
    font-size: 30px;
  }
  
  p{
    font-family: CormorantGaramond; 
    font-size: 25px;
  }
  
  #ColbyAccess {
   width: 100% ;
   height: 100%;
   padding-top: 2em;
   padding-bottom: 0em;

   } 

   .caption{
    font-family: CormorantGaramond-Italic; 
    font-size: 25px;
    padding-bottom: 2em;
    text-align: center;
   }

   .sushi{
    width: 70% ;
    height: 70%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-top: 2em;
    padding-bottom: 2em;
   }
  
   .parent-portfolio {
    padding: 20px;
    padding-left: 0px;
    width:80%;
    float:right;
  }
  
  .child-portfolio {
    width: 100%;
    float: left;
    padding: 5px;
    padding-left:20px;
  }

  @media screen and (max-width : 900px) {
    .section-portfolio{
      font-size: 30px;
    }
    .project{
      font-family: CormorantGaramond-Bold; 
      font-size: 25px;
    }
    p{
      font-size: 20px;
    }
    .caption{
      font-size: 20px;
    }
    #ColbyAccess {
      height: 100%;
      width: 100% 
      } 

    .sushi{
      width: 100%;
      height: 100%;
    }
  }
  
  @media screen and (max-width : 600px) {
    p{
      font-size: 15px;
    }
    .project{
      font-family: CormorantGaramond-Bold; 
      font-size: 20px;
    }
    .caption{
      font-size:15px;
    }
    .sushi{
      width: 100%;
      height: 100%;
    }
    #ColbyAccess {
      float:none;
      height: 100%;
      width: 100% 
      } 
  }