/* change the background color */
#navbar.bg-navbar {
    background-color: 'transparent';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 17vh;
    z-index: 0;
}

.navbar-nav {
    float:none;
    margin:0 auto;
    display: block;
    text-align: center;
    font-family: CormorantGaramond;
    font-size: 40px;
}

.navbar-nav > li {
    display: inline-block;
    float:none;
    padding-left:15px;
    padding-right:15px;
}
.navbar .navbar-nav > li > a,
.navbar .navbar-nav > .active > a{
    color: white;
    text-decoration:none
}

.navbar .navbar-nav > li > a:hover{
    color: rgb(231, 91, 10);
    text-decoration: none
}

@media screen and (max-width : 900px) {
    .navbar-nav{
        font-size: 30px;
    }
  }
  
  @media screen and (max-width : 600px) {
    .navbar-nav{
        font-size: 25px;
    }
  }
