
.sidebar {
    background-color: black;
    position:fixed;
    width:20%;
    height:100%;
    left: 0;
    right: 0;
    z-index: 1;
    padding-bottom: 20px;
    padding-top: 20px;
    font-family: CormorantGaramond;
    font-size: 30px;
    text-align: center;

}

@media screen and (max-width : 900px) {
    .sidebar{
        height:100%;
        width: 20%;
        font-size:20px
    }
    .containers{
        flex-wrap: wrap;
    }
    .containers a{
        font-size: 10px;
    }
  }

  @media screen and (max-width : 450px) {
    .sidebar{
        height:100%;
        width: 20%;
        font-size:15px
    }
    .containers a{
        font-size: 7px;
    }
  }

  @media screen and (max-width : 380px) {
    .sidebar{
        font-size:13px
    }
  }


ul{
    padding-left:0px
}

.item {
    display: block;
    padding-left:15px;
    padding-right:15px;
    padding-bottom:10px;
    padding-top:10px;
}
.item> a,
.item.active > a{
    color: rgb(255, 255, 255);
    text-decoration:none;
}

.item> a:hover{
    color: rgba(174, 25, 233, 0.781);
    text-decoration: none
}
hr{
    border-top: 1px solid rgba(124, 20, 165, 0.781);
    width:50%;
}

.containers{
    font-size: 15px;
}

@media screen and (max-width : 900px) {
    .containers a{
        padding-top:10px;
        padding-bottom:10px;
    }

  }


