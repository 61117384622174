.containers {
    display:flex;
    justify-content: center;
    position: absolute;
    left: 0;
    right: 0; 
    bottom: 12vh;
    z-index: 0;

}

.react-icons1 {
    color:white;
  }
.react-icons1:hover{
    color:rgb(67, 67, 241);
}

.react-icons2 {
    color:white;  
  }
.react-icons2:hover{
    color: rgb(80, 74, 74);
}

.react-icons3 {
    color:white;  
  }
.react-icons3:hover{
    color:red;
}

.containers a {
    display: inline-block;
    float:none;
    padding-left:20px;
    padding-right:20px;
    
}
