@font-face {
  font-family: 'CormorantGaramond';
  src: local('CormonrantGaramond'), url(./fonts/CormorantGaramond-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'CormorantGaramond';
  font-weight: 900;
  src: local('CormonrantGaramond'), url(./fonts/CormorantGaramond-Bold.ttf) format('truetype');
}

/* @font-face {
  font-family: 'CormorantGaramond';
  src: local('CormonrantGaramond'), url(./fonts/CormorantGaramond-Regular.ttf) format('truetype'); */
/* } */