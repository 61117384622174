.banner {
    color: white;
    background-color: transparent;
    position: absolute;
    left: 0;
    right: 0;
    /* top:0; */
    bottom: 75vh;
    z-index: 0;
    float:none;
    margin:0 auto;
    display: block;
    text-align: center;
    font-family: CormorantGaramond;
    font-size: 30px;
} 

@media screen and (max-width : 900px) {
    .banner{
        font-size: 30px;
    }
  }
  
  @media screen and (max-width : 600px) {
    .banner{
        font-size: 25px;
    }
  }
