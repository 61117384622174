body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title-line{
  border-top: 1px solid rgba(124, 20, 165, 0.781);
  text-align: left;
  margin-left:0;
  width:300px;
}

@media screen and (max-width : 450px) {
  .title-line{
    width:200px;
  }
}
